import React from "react";
import { NavLink, Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import menu from "../images/menu.svg";
import Logo from "../images/logo.png";
const Header = () => {
  return (
    <>
      <header className="header-top-strip">
        <div className="container-xxl">
          <div className="row">
            <div className="col-11">
            <div className="menu-links">
                  <div className="d-flex align-items-center justify-content-end gap-15 text-light">
                  <NavLink to="/blogs">Blog</NavLink>
                  <NavLink to="/sales-team">Sales Team</NavLink>
                  <NavLink to="/it-support">IT Support</NavLink>
                    <NavLink to="/help">Help</NavLink>
                    <NavLink to="tel:7028502797">(702) 850-2797</NavLink>
                  </div>
                </div>
                </div>
            <div className="col-1">
            <div>
                  <Link
                    to="/cart"
                    className="d-flex align-items-center justify-content-end text-white"
                  >
                    <p className="mb-0">$ 500</p>
                    <div className="d-flex flex-column gap-10 header-cart">
                      <span className="badge text-light">0</span>
                      <img src={cart} alt="cart" />
                      
                    </div>
                  </Link>
                </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-upper py-1">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-2">
              <h2>
                <Link className="main-logo"><img src={Logo} width="196" alt="Logo" /></Link>
              </h2>
            </div>
            <div className="col-8">
            <div className="menu-links header-menu">
                  <div className="d-flex align-items-center justify-content-start gap-15">
                    <NavLink to="/">Catalog</NavLink>
                    <NavLink to="/product">Sell/ Trade In</NavLink>
                    <NavLink to="/financing">Financing</NavLink>
                    <NavLink to="/blogs">Blog</NavLink>
                    <NavLink to="/about">About Us</NavLink>
                    <NavLink to="/resources">Resources</NavLink>
                    <NavLink to="/contact">Contact Us</NavLink>
                    <NavLink to="/it-support">IT Support</NavLink>
                  </div>
                </div>


            </div>
            <div className="col-2 ysm-search-widget ysm-search-widget-1">
            <input type="search" name="s" value="" id="ysm-smart-search-1-6434a654a1eeb" class="search-field" placeholder="Search …" autocomplete="off"></input>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
