import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
//import { services } from "../utils/Data";

const Home = () => {
  return (
    <>
      <Container class1="home-wrapper-1">
        <div className="row">
          <div className="col-12">
            <div className="main-banner position-relative ">
              <img
                src="images/Mediaplayer-scaled.jpeg"
                className="img-fluid"
                alt="main banner"
              />
              <div className="main-banner-content position-absolute">
                <h4>THE LEADER IN</h4>
                <h5>DENTAL X-RAY IMAGING EQUIPMENT</h5>
                <p>We provide complete delivery, training, installation, and warranties on all our products</p>
                <Link className="button">SCHEDULE A DISCOVERY CALL</Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className='banner-bottom'>
          <div className='container'>
            <h2>Affordable Imaging Equipment to Elevate Your Standard of Care</h2>
          </div>
        </div>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-between">

            </div>
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="counter d-flex flex-wrap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div>
                  <h6>5,163</h6>
                  <p>PIECES SOLD</p>
                </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div>
                  <h6>2,228</h6>
                  <p>CUSTOMERS</p>
                </div>
               
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div>
                  <h6>31</h6>
                  <p>CUSTOMERS</p>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-center">
            <Link className="button">LEARN MORE</Link>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="featured-wrapper py-5 home-wrapper-2">
        <div className="row">
        <div className="col-12">
            <div className="features d-flex flex-wrap align-items-center">
              <div className="col-3 d-flex justify-content-center gap align-items-center">
              <div className="main-banner-content featured-left">
                <h4>FIND BY</h4>
                <h5>PRODUCT</h5>
                <p>Certified pre-owned dental equipment for all your dental needs.</p>
                <Link className="button">VIEW ALL</Link>
              </div>
              </div>
              <div className="col-9 d-flex-full justify-content-center gap align-items-center">
              <Container class1="featured-wrap">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">INTRAORAL SENSOR</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">CEPHALOMETRIC</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">CONE BEAM</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </Container>
        <Container class1="featured-wrap">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </Container>
                
              </div>
            </div>
            
          </div>
        </div>
      </Container>

      <Container class1="featured-wrapper find-area py-5 home-wrapper-2">
        <div className="row">
        <div className="col-12">
            <div className="features d-flex flex-wrap align-items-center">

              <div className="col-9 d-flex-full justify-content-center gap align-items-center">
              <Container class1="featured-wrap">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </Container>
        <Container class1="featured-wrap">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </Container>
                
              </div>
              <div className="col-3 d-flex justify-content-center gap align-items-center">
              <div className="main-banner-content featured-left">
                <h4>FIND BY</h4>
                <h5>AREA OF USE</h5>
                <p>Find the right x-ray for your treatment requirements.</p>
                <Link className="button">VIEW ALL</Link>
              </div>
              </div>
            </div>
            
          </div>
        </div>
      </Container>



      <Container class1="featured-wrapper find-area py-5 home-wrapper-2">
        <div className="row">
        <div className="col-12">
            <div className="features d-flex flex-wrap align-items-center">
            <div className="col-12 d-flex justify-content-center gap align-items-center">
              <div className="main-banner-content featured-left byparts">
                <h4>FIND BY <span>PARTS</span></h4>
                
                <p>Locate new, recertified and obsolete parts for your equipment.</p>
                
              </div>
              </div>
              <div className="d-flex-full justify-content-center gap align-items-center">
              <div class="featured-wrap find-by-parts-first">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </div>
        <Container class1="featured-wrap find-by-parts">
              <div className="row">
          <div className="col-12">
            <div className="featured-right d-flex gap align-items-center">
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
              <div className="d-flex justify-content-center gap align-items-center">
              <div className="famous-card position-relative">
              <img
                src="images/product1.png"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                
                
                <Link className="button featured-button">VIEW ALL</Link>
              </div>
            </div>
                
              </div>
            </div>
            
          </div>
        </div>
        </Container>
                
              </div>

            </div>
            
          </div>
        </div>
      </Container>


      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-center">
            <Link className="button">VIEW ALL</Link>
            </div>
          </div>
        </div>
      </Container>



      <Container class1="featured-wrapper py-5 home-wrapper-22 collection-grid">
        <div className="row">
        <div className="col-12 d-flex justify-content-center gap align-items-center">
              <div className="main-banner-content featured-left byparts">
                <h4>FEATURED <span>PRODUCTS</span></h4>
                
                <p>Our Deals are updated weekly so keep checking back.</p>
                
              </div>
              </div>
          <div className="collection-grids row">
          <ProductCard />
          <ProductCard />
          <ProductCard />
          </div>
        </div>
      </Container>

      <Container class1="home-wrapper-22 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-center">
            <Link className="button">VIEW ALL</Link>
            </div>
          </div>
        </div>
      </Container>


      <Container class1="home-wrapper-22">
        <div className="row">
              <div className="col-12 d-flex justify-content-center gap align-items-center py-5">
              <div className="main-banner-content featured-left byparts trusted-brands">
              <h4>INDUSTRY <span>PARTNERS</span></h4>   
              </div>
              </div>
          <div className="col-12">
            <div className="counter d-flex flex-wrap align-items-center">
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div><Link className="brand-url">
                <img
                src="images/logo2.png"
                className="img-fluid"
                alt="famous"
              />
              </Link>
                </div>
                
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div><Link className="brand-url">
                <img
                src="images/logo-1.png"
                className="img-fluid"
                alt="famous"
              /></Link>
                </div>
               
              </div>
              <div className="col-4 d-flex justify-content-center gap align-items-center">
                <div><Link className="brand-url">
                <img
                src="images/dental.jpg"
                className="img-fluid"
                alt="famous"
              /></Link>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </Container>

      <Container class1="blog-wrapper py-5 home-wrapper-2">
        <div className="row">
        <div className="col-12 d-flex justify-content-center gap align-items-center py-5">
              <div className="main-banner-content featured-left byparts">
                <h4><span>INSIGHTS</span></h4>
                
              </div>
              </div>
        </div>
        <div className="row">
          <div className="col-4">
            <BlogCard />
          </div>
          <div className="col-4">
            <BlogCard />
          </div>
          <div className="col-4">
            <BlogCard />
          </div>
          
        </div>
      </Container>
      <Container class1="marque-wrapper home-wrapper-22 py-5">
        <div className="row">
        <div className="col-12 d-flex justify-content-center gap align-items-center">
              <div className="main-banner-content featured-left byparts trusted-brands">
                <h4>BRANDS <span>TRUSTED BY DENTISTS</span></h4>
                
                
                
              </div>
              </div>
          <div className="col-12">
            <div className="marquee-inner-wrapper card-wrapper-full">
              <Marquee className="d-flex">
                <div className="mx-4 w-25">
                  <img src="images/brand-01.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-02.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-03.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-04.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-05.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-06.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-07.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-08.png" alt="brand" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>

      <Container class1="testimonial-wrapper home-wrapper-22">
        <div className="row">
        <div className="col-12 d-flex justify-content-center gap align-items-center py-5">
              <div className="main-banner-content featured-left byparts trusted-brands">
                <h4> <span>TESTIMONIALS</span></h4>
                
                
                
              </div>
              </div>
          <div className="col-12">
            <div className="testimonial-inner-wrapper">
            <div className="row">
          <div className="col-4">
          <div class="sp-testimonial-free">
            <div class="sp-testimonial-title">
            
            </div><div class="sp-testimonial-client-testimonial">
            <div class="sp-testimonial-client-testimonial">
              <div class="sp-testimonial-content">
                <p>Cameron called my contact phone number one week prior to installation date to introduce himself 
                  to me, and assured that all the coordination has been set in motion for a $20,000+ purchase. On the morning of 
                  installation, our office was able to continue operations smoothly with him working behind the scenes while he 
                  broke down a 1,000+ lb crate and installed the CBCT Scanner, and by the next morning, we have a fully functional 
                  unit that we can start providing top class care with. Fantastic experience overall.</p></div>
                  
                  <h3 class="sp-testimonial-post-title">Danny Nguyen</h3>
                  </div>
                </div>
                </div>
          </div>
          <div className="col-4">
          <div class="sp-testimonial-free">
            <div class="sp-testimonial-title">
            
            </div><div class="sp-testimonial-client-testimonial">
            <div class="sp-testimonial-client-testimonial">
              <div class="sp-testimonial-content">
                <p>Cameron called my contact phone number one week prior to installation date to introduce himself 
                  to me, and assured that all the coordination has been set in motion for a $20,000+ purchase. On the morning of 
                  installation, our office was able to continue operations smoothly with him working behind the scenes while he 
                  broke down a 1,000+ lb crate and installed the CBCT Scanner, and by the next morning, we have a fully functional 
                  unit that we can start providing top class care with. Fantastic experience overall.</p></div>
                  
                  <h3 class="sp-testimonial-post-title">Danny Nguyen</h3>
                  </div>
                </div>
                </div>
          </div>
          <div className="col-4">
          <div class="sp-testimonial-free">
            <div class="sp-testimonial-title">
            
            </div><div class="sp-testimonial-client-testimonial">
            <div class="sp-testimonial-client-testimonial">
              <div class="sp-testimonial-content">
                <p>Cameron called my contact phone number one week prior to installation date to introduce himself 
                  to me, and assured that all the coordination has been set in motion for a $20,000+ purchase. On the morning of 
                  installation, our office was able to continue operations smoothly with him working behind the scenes while he 
                  broke down a 1,000+ lb crate and installed the CBCT Scanner, and by the next morning, we have a fully functional 
                  unit that we can start providing top class care with. Fantastic experience overall.</p></div>
                  
                  <h3 class="sp-testimonial-post-title">Danny Nguyen</h3>
                  </div>
                </div>
                </div>
          </div>
          
        </div>
            </div>
          </div>
        </div>
      </Container>

    </>
  );
};

export default Home;
