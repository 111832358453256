import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import newsletter from "../images/newsletter.png";
const Footer = () => {
  return (
    <>

      <footer className="py-5">
        <div className="container-xxl">
          <div className="row">
            <div className="col-4 contact-col">
              <h4 className="mb-4">Contact <span>Us</span></h4>
              <div>
                <address className="text-white fs-6">
                <p>440 US Hwy 377</p>
<p>Suite 1140</p>
Argyle, Texas 76226
                </address>
                <h5 className="text-white mb-4"><i>Serving the dental industry since 1986</i></h5>
                
              </div>
            </div>
            <div className="col-3">
              
              <div className="footer-link footer-address d-flex flex-column">
              <a
                  href="tel:7028502797"
                  className="d-block mb-1 text-white"
                >
                  Office: (702) 850-2797
                </a>
                <a
                  href="tel:7028502805"
                  className="d-block mb-1 text-white"
                >
                  Fax: (702) 850-2805
                </a>
                <a
                  href="mailto:sales@globalimagingusa.com"
                  className="mt-2 d-block mb-0 text-white"
                >
                  Email: sales@globalimagingusa.com
                </a>
              </div>
            </div>
            <div className="col-5 footer-address">
              
              <div className="footer-link footer-menu d-flex flex-wrap justify-content-end gap-15">
                
                <Link className="text-white first-child">Catalog</Link>
                <Link className="text-white">Blog</Link>
                <Link className="text-white">About Us</Link>
                <Link className="text-white">Privacy Policy</Link>
                <Link className="text-white">Warranty</Link>
                <Link className="text-white">Logistics/Shipping</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-5 copyright">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()}; Global Imaging USA , All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
